<template>
  <div class="interface">
    <b-card class="login-card">
      <div class="logo">
        <img src="https://dashboard.businessindicator.com/images/logo.png" />
      </div>
      <div>
        <span>Entre com o seu CPF/CNPJ para recuperar a conta</span>
        <b-form @submit.prevent="requestCode">
          <div class="h-separator">
            <label for="cpf" class="title">CPF/CNPJ *</label>
            <span></span>
          </div>
          <b-form-input
            id="cpf"
            v-model="user.cpf"
            type="text"
            placeholder="CPF/CNPJ"
            v-maska="['###.###.###-##', '##.###.###/####-##']"
            required
          ></b-form-input>
          <div class="h-separator">
            <label for="cpf" class="title">E-mail *</label>
            <span></span>
          </div>
           <b-form-input
            id="email"
            v-model="user.email"
            type="text"
            placeholder="E-mail"
            required
          ></b-form-input>
          <div class="h-separator">
            <label for="cpf" class="title">Grupo *</label>
            <span></span>
          </div>
           <b-form-select
            id="grupo"
            v-model="user.group"
            type="select"
            placeholder="Selecione um grupo"
            required
          >
            <option disabled value="">Selecione um grupo</option>
            <option value="Super Admin">Super Admin</option>
            <option value="Admin">Admin</option>
            <option value="Client">Client</option>
          </b-form-select>
          <b-button type="submit">Entrar</b-button>
        </b-form>
      </div>
    </b-card>
  </div>
</template>

<style lang="stylus" scoped>
@import '../style/colors.styl';
@import '../style/fonts.styl';

.interface
  min-height 100%
  min-height 100vh
  display flex
  align-items center
  background 50%/cover no-repeat url('https://dashboard.businessindicator.com/images/bg_login.png')

.login-card
  position absolute
  right 5%
  width 350px
  min-height 52% // old support
  min-height 52vh
  border-radius 0.5rem
  margin 0 auto
  display flex
  background light

@media (max-width: 556px)
    .login-card
        position unset
        margin 0 auto
        width: 90%
        min-height 75vh

.card-body, .card-body > div
  display grid
  justify-content space-around

.logo
  margin-bottom 30px
  margin-bottom: 30px;
  align-content: center;
  align-self: center;
  justify-items: center;

  img
    width: 50%

.h-separator
    display flex
    .title
        padding 0px 10px
        font-size 14px
        color light
        font-weight bold
        border-radius 1rem
        background primary
    span
        align-self center
    span:last-child
        border 1px dashed primary
        height 1px
    span:last-child
        flex-grow 1

form
  width 100%

  *
    font-family Manrope

  .title
    text-align center
    color primary

  label
    color light
    padding 0 0.5rem
    margin 8px 0
    border-radius 1rem
    font-weight bold
    background primary

  input, button
    height calc(1.5em + 0.75rem + 2px)
    padding 0.4rem 1rem

  input
    margin-bottom 18px
    border-radius 0.5rem
    background white
    color secondary

  button
    width 100%
    margin 10px 0 0 0
    background secondary
</style>

<script>
/* eslint-disable */
import { request } from '@/services'
import { toast, errorFormater } from '@/utils'

export default {
  data: () => ({
    user: {
      cpf: '',
      email: '',
      group: ''
    }
  }),
  methods: {
    requestCode: function () {
      request.post('recover/request', { cpf: this.user.cpf, email: this.user.email, group: this.user.group },
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            this.$router.push({
              name: 'RecoverConfirm',
              params: { cpf: this.user.cpf }
            })
          }
        },
        (error) => {
          toast(this, 'danger', 'Erro', errorFormater(error))
          return Promise.reject(error)
        })
    }
  }
}
</script>